.head {
  background-image: $goldGradient2;
  padding: 75px 20px;
  color: $white;
  position: relative;

  &-green {
    background-image: $greenGradient2;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }

  .promo {
    position: absolute;
    right: 0;
    bottom: -40px;
    z-index: 4;
    padding: 0 18px;
    width: 50%;
    max-width: 500px;
    min-width: 300px;
    overflow: hidden;

    .bg {
      &:after {
        content: '';
        position: absolute;
        background-color: $greenLight;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        min-width: 350px;
        height: 130px;
        clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
      }
    }

    .promo-content {
      position: relative;
      z-index: 8;
      width: calc(100% + 36px);
      left: -17px;
      clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);

      p {
        margin: 0;
        text-align: center;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 64px;
          line-height: 53px;
          font-weight: 700;
          position: relative;
          margin: 10px 30px;
          left: -10px;

          &:after {
            content: '%';
            position: absolute;
            top: -10px;
            font-size: 25px;
            font-weight: 700;
          }
        }
      }
    }

    .info {
      background-color: #d2d9d7;
      color: $black;
      font-size: 10px;
      line-height: 12px;
      padding: 8px 16px;
      text-align: left;
      position: relative;
      z-index: 20;
      width: 100%;
      left: -18px;
      clip-path: polygon(8px 0%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  .logo {
    width: 250px;
    padding: 20px;
  }

  h1 {
    font-size: 60px;
    line-height: 57px;
    text-transform: uppercase;
    font-weight: 500;

    b {
      font-weight: 900;
    }

    .top {
      font-size: 20px;
      line-height: 44px;
      display: block;
      margin-bottom: 5px;
    }
  }

  a {
    text-decoration: none;
    color: #ffffff;
    padding: 16px 30px;
    display: flex;
    align-items: center;
    transition: 300ms;
    font-size: 14px;

    .icon-wrapper {
      border: 1px solid #fff;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      min-width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;
      transition: 300ms;
    }

    &:hover .icon-wrapper {
      transform: rotate(90deg);
    }
  }

  @media (max-width: 899px) {
    .wrapper {
      flex-direction: column;
    }

    .logo {
      margin-top: 30px;
      padding: 0;
      width: 100%;
      max-width: 200px;
    }

    h1 {
      font-size: 28px;
      line-height: 32px;
    }

    .promo {
      padding: 0 4px;
      width: 70%;
      margin-top: -20px;

      .bg {
        &:after {
          height: 110px;
        }
      }

      .promo-content {
        position: relative;
        z-index: 8;
        width: 100%;

        p {
          margin: 0;
          font-size: 14px;
          line-height: 16px;

          span {
            font-size: 40px;
            line-height: 44px;
            font-weight: 700;
            margin: 8px 20px;

            &:after {
              content: '%';
              position: absolute;
              top: -10px;
              font-size: 18px;
            }
          }
        }
      }

      .info {
        padding-left: 30px;
        left: -4px;
      }
    }
  }

  @media (max-width: 660px) {
    padding-bottom: 125px;

    .promo {
      width: 100%;

      .bg {
        &:after {
          clip-path: none;
        }
      }

      &-content {
        left: 0 !important;
      }

      max-width: 100%;

      .info {
        left: 0;
        right: 0;
        box-sizing: border-box;
        clip-path: none;
        display: block;
      }
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
}