.title {
  font-size: 50px;
  font-weight: 900;
  line-height: 48px;
  margin-bottom: 30px;
  .top {
    font-size: 20px;
    font-weight: 500;
    line-height: 44px;
    display: block;
  }
  .highlight {
    color: $goldDark;
  }
  .small {
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 44px;
  }
}
