.block-image-wrapper {
  &-highlighted {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      background: $goldLighten;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.block-form,
.block-image {
  position: relative;
  z-index: 2;
  padding: 50px 20px;
  color: $black;
  .text-block {
    padding: 0 20px;
    margin: 0;

    .content-two {
      .block-head {
        h3 {
          font-size: 50px;
          font-weight: 900;
          line-height: 65px;
          text-transform: uppercase;
          .top {
            font-size: 28px;
            font-weight: 500;
            line-height: 48px;
          }
        }
      }
      .block-body {
        p {
          line-height: 33px !important;
        }
      }
    }
    h3 {
      font-size: 50px;
      line-height: 48px;
      text-transform: uppercase;
      b {
        font-weight: 900;
      }
      .top {
        font-size: 20px;
        display: block;
      }
      .highlight {
        color: $gold;
      }
    }
    .block-head {
      margin: 20px 0;
      .highlight {
        color: $gold;
      }
    }
    .block-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      color: $gray;
      p {
        font-size: 18px;
        line-height: 20px;
        b {
          font-weight: 700;
        }
      }
      ul {
        list-style: none;
        padding-left: 5px;
        margin: 20px 0 40px;
        li {
          position: relative;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 400;
          &:before {
            content: '.';
            font-size: 22px;
            position: relative;
            top: -4px;
            left: -10px;
          }
        }
      }
      .btn {
        margin-top: 40px;
        margin-left: 0;
        max-width: 350px;
      }
      .flowed {
        display: block;
        color: $black;
        margin-top: 60px;
        position: relative;
        .content {
          position: relative;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:after {
          content: '';
          background-color: #fffbf8;
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          left: -30px;
          right: 0;
          bottom: 0;
          width: 100vw;
        }
        h3 {
          font-size: 32px;
          line-height: 25px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
          b {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .image-block {
    justify-content: center;
    align-items: center;
    display: flex;
    &.flex-start {
      img {
        margin-right: auto;
        margin-left: 0;
      }
    }
    &.flex-end {
      img {
        margin-left: auto;
        margin-right: 0;
      }
    }
    img {
      width: 100%;
      max-width: 712px;
      margin: auto;
      border-radius: 15px;
    }
  }
  &.block-image-highlighted {
    background-color: $goldLighten;
    border-radius: 0 20px 20px 0;
    padding: 60px 20px;
    .text-block {
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        b {
          font-size: 20px;
          font-weight: 700;
          line-height: 22px;
        }
      }
    }
    .image-block {
      img {
        position: relative;
        top: -110px;
      }
    }
  }
  @media (max-width: 899px) {
    padding: 40px 18px;
    .text-block {
      h3 {
        font-size: 36px;
        line-height: 40px;
        .top {
          font-size: 18px;
          line-height: 44px;
        }
      }

      .content-two {
        .block-head {
          h3 {
            font-size: 36px;
            line-height: 38px;
            .top {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
      .block-body {
        .btn {
          margin-top: 0;
        }
      }
    }
    .image-block {
      padding: 0;
      img {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        display: flex;
      }
    }
    &.block-image-highlighted {
      padding: 40px 20px;
      .text-block {
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          b {
            font-size: 20px;
            font-weight: 700;
            line-height: 22px;
          }
        }
      }
      .image-block {
        img {
          position: relative;
          top: 0;
          margin: auto;
        }
      }
    }
  }
}
