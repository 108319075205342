@import './variables/';
@import './shared/';
// Components
@import './components/smallIntro.scss';
@import './components/rightSide.scss';
@import './components/leftSide.scss';
@import './components/form';
@import './components/header';
@import './components/footer';
@import './components/styledText';
@import './components/button';
@import './components/intro';
@import './components/cards';
@import './components/textSides';
@import './components/cardSides';
@import './components/benefits';
@import './components/steps';
@import './components/head';
@import './components/block';
@import './components/formProlong';
@import './components/stepsTwo';
@import './components/formLogin';
@import './components/title';
@import './components/cardFinance';
@import './components/blockMaster';
@import './components/partners';
@import './components/loading';
@import './components/items';
@import './components/blockError';

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: $black;
}

a {
  // text-decoration: none;
}

.highlight {
  color: $gold;
}

.new-line {
  display: inline-block;
}

.hidden {
  visibility: hidden;
  position: absolute;
}