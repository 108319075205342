.block-login {
  background-image: $greenGradient2;
  padding: 116px 20px;
  overflow: hidden;

  h3 {
    color: $white;
    text-align: left;
    max-width: 748px;
    width: 100%;
    margin: auto;

    .top {
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 44px;
    }

    .bottom {
      display: block;
      font-size: 50px;
      font-weight: 900;
      line-height: 52px;
    }
  }

  @media (max-width: 899px) {
    padding: 80px 0;
  }
}

.form-login {
  margin-top: 50px;
  width: 100%;

  .form-content {
    padding: 65px 160px 64px 80px;
    width: 100%;
    background: $white;
    max-width: 890px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 40%;
      height: 120%;
      top: -10%;
      right: -20%;
      background-image: url("https://i.imgur.com/j7be2Af.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .btn {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 899px) {
    padding: 40px 0;

    .form-content {
      padding: 40px 30px;

      &:after {
        display: none;
      }
    }
  }
}