.benefits {
  padding: 60px 20px 50px;
  h2 {
    color: $black;
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
    span {
      color: $gold;
    }
    @media (max-width: 1058px) {
      width: 100%;
      font-size: 38px;
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    color: $black;
    justify-content: space-between;
    max-width: 870px;
    margin: auto;
  }
  .card {
    border-radius: 15px;
    background-color: $goldLighten;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    position: relative;
    .indecator {
      &-icon {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: 1px solid $gray;
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: $black;
        &:after {
          content: "i";
          color: $white;
          text-align: center;
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          display: block;
        }
        &:hover {
          ~ .indecator-content {
            display: block;
          }
        }
      }
      &-content {
        position: absolute;
        background-color: $goldLighten;
        border-radius: 15px;
        border: 1px solid $gray;
        bottom: calc(100% - 40px);
        right: 20px;
        z-index: 1;
        font-size: 13px;
        line-height: 17px;
        display: none;
        &:hover {
          display: block;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
      padding: 22px 8px;
    }
  }
  @media (max-width: 899px) {
    padding: 60px 8px;
    h2 {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .cards {
      justify-content: space-between;
    }
    .card {
      width: 100%;
      p {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 18px 8px;
      }
    }
  }
}
