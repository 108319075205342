header {
  padding-top: 50px;
  padding-bottom: 30px;
  color: $white;
  .logo {
    width: 200px;
    margin-left: 20px;
    @media (max-width: 900px) {
      width: 100%;
      max-width: 150px;
      min-width: 90px;
    }
  }
  .logo-wrapper {
    margin-top: 0;
  }
  .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    .logout {
      color: $black;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        p {
          margin-right: 8px;
        }
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-end;
      padding: 20px;
      .menu-toggler {
        position: absolute;
        top: -10px;
        right: 0px;
      }
      ul {
        padding-top: 36px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        text-align: center;
        li {
          display: none;
        }
      }
    }
    &.mobile-show {
      ul > li {
        display: flex;
        padding-top: 10px;
        margin-left: auto;
      }
    }
  }
  .menu-toggler {
    color: $gray;
    background-color: $white;
    outline: none;
    display: none;
    border: none;
    @media (max-width: 900px) {
      display: block;
    }
  }
  ul {
    display: flex;
    li {
      justify-content: center;
      display: flex;
      text-align: center;
    }
    a {
      text-decoration: none;
      font-size: 15px;
      color: $black;
      margin: 5px;
      padding: 8px;
      line-height: 24px;
      &.active {
        font-weight: 700;
      }
    }
  }
  @media (max-width: 900px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
