.btn {
  display: flex;
  outline: none;
  border: none;
  color: $white;
  align-items: center;
  justify-content: space-between;
  background-color: $goldDark;
  padding: 22px 30px;
  width: 100%;
  max-width: 294px;
  margin: 6px 12px;
  cursor: pointer;

  &:hover {
    .icon-wrapper {
      transform: rotate(90deg);
    }
  }

  .text-wrapper {
    font-size: 16px;
    font-weight: 700;
    line-height: 15px;
    text-align: left;
  }

  .icon-wrapper {
    transition: transform 0.3s ease-in-out;
    border: 1px solid $white;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    min-width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  &-outlined {
    width: auto;
    max-width: unset;
    align-self: flex-start;

    .icon-wrapper {
      margin-left: 10px;
    }
  }
}