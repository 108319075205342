.cards-finance {
  color: $white;

  >div {
    position: relative;

    &:nth-child(1) {
      background: linear-gradient(145.96deg, #59706d -13.22%, #b1c5bf 262.07%);

      &:after {
        background-image: url("../../assets/images/carSketchFront.svg");
        opacity: 0.2;
      }
    }

    &:nth-child(2) {
      background: linear-gradient(160.48deg, #ce9f69 0%, #b9764f 238.59%);

      &:after {
        background-image: url("../../assets/images/carSketchBack.svg");
      }
    }
  }

  &-details {
    >div {
      background: #000;

      &:after {
        display: none;
      }

      background-image: none !important;
    }
  }

  .card-finance-details,
  .card-finance {
    height: 623px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 20px;
      max-width: 600px;
      top: 50px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: 30px;
        margin-left: 0;
      }
    }
  }

  .card-finance-details {
    background-color: $white;
    color: $black;
    padding: 30px 0;

    .title {
      .highlight {
        color: $gold;
      }
    }

    .content {
      top: 0;
      max-width: 720px;
    }

    ul {
      li {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 899px) {
  .cards-finance {
    .card-finance-details {
      height: auto;
    }

    .card-finance {
      height: auto;

      .content {
        padding: 40px 20px;
        top: 0;

        .title {
          font-size: 30px;
          line-height: 32px;
          text-align: center;

          .top {
            font-size: 25px;
            line-height: 27px;
          }
        }

        .btn {
          margin: auto;
        }
      }
    }
  }
}