.block-master {
  &-1 {
    width: 100%;
    margin: 200px 0 100px;

    .inner {
      position: relative;
      background: linear-gradient(180deg, #486c67 0%, #2e9083 100%);
      color: $white;
      border: 8px solid $white;
      border-right: 0;
      border-left: 0;
    }

    .after {
      position: absolute;
      bottom: calc(-10% + 15px);
      left: 0;
      width: 65%;
      height: 68px;
      z-index: -1;
    }

    .img-wrapper {
      display: flex;
      flex-direction: column;

      .before {
        position: absolute;
        top: calc(-10% - 120px);
        height: 120px;
        max-width: 30%;
      }

      .img {
        position: absolute;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 100% 100%;
        width: 50%;
        max-width: 670px;
        height: 120%;
        top: -10%;
        left: 0;
        z-index: 2;
      }
    }

    .content {
      width: 50%;
      position: relative;
      margin-left: auto;

      .logo {
        position: absolute;
        width: 40%;
        max-width: 330px;
        right: 10%;
        top: -25%;
      }

      &-inner {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 150px 20% 150px 10px;

        h3 {
          margin: 30px auto;
          font-family: "Montserrat", sans-serif;
          font-weight: 800;
          font-size: 48px;
          line-height: 59px;
          text-align: right;
          text-transform: uppercase;

          color: #ffffff;
        }

        .btn {
          margin: 30px 0 30px auto;
          background-color: $white !important;
          color: #54827d !important;

          .icon-wrapper {
            border-color: #54827d;
          }
        }

        @media (max-width: 1270px) {
          padding-top: 100px;
          padding-bottom: 100px;
          padding-right: 40px;

          h3 {
            font-size: 40px;
            line-height: 55px;
          }
        }

        @media (max-width: 1028px) {
          padding-top: 70px;
          padding-bottom: 70px;
          padding-right: 30px;

          h3 {
            font-size: 30px;
            line-height: 40px;
          }
        }

        @media (max-width: 860px) {
          padding-top: 40px;
          padding-bottom: 40px;
          padding-right: 30px;

          h3 {
            font-size: 25px;
            line-height: 35px;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      .wrapper {
        .img {
          background-size: auto 100%;
        }
      }
    }

    @media (max-width: 767px) {
      .wrapper {
        .img {
          display: none;
        }

        .content {
          .logo {
            width: 220px;
            top: -160px;
            right: 10px;
          }

          width: 100%;

          .content-inner {
            padding-right: 20px;
            padding-left: 20px;
          }

          h3 {
            text-align: center;
          }

          .btn {
            margin: auto;
          }
        }
      }
    }
  }

  &-title,
  &-text {
    width: 100%;
    max-width: 1118px;
    text-align: center;
    margin: 50px auto 50px;

    p {
      font-size: 24px;
      line-height: 36px;
      color: #4e4e4e;

      &.bold,
      &.top {
        font-weight: bold;
        margin-bottom: 40px;
      }
    }
  }

  &-title {
    margin-bottom: 0;
  }

  &-text {
    padding: 0 20px;

    @media (max-width: 767px) {
      p:not(.top) {
        text-align: justify;
      }
    }
  }

  &-2 {
    width: 100%;
    padding-left: 6%;

    .wrapper {
      position: relative;

      .img {
        position: absolute;
        background-repeat: no-repeat;
        background-position: left center;
        // background-size: 100% 790px;
        background-size: 100% 101%;
        right: 0;
        width: 45%;
        max-width: 780px;
        height: 100%;
      }

      .after {
        position: absolute;
        bottom: -75px;
        right: 0;
      }
    }

    .card {
      background: linear-gradient(180deg, #486c67 0%, #2e9083 100%);

      padding: 55px 55px;
      margin-bottom: 15px;
      color: $white;

      &-inner {
        width: 100%;
        max-width: 62%;
      }

      &:nth-child(2) {
        .card-inner {
          max-width: 50%;
        }
      }

      &-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        margin-bottom: 25px;
      }

      &-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
      }
    }

    @media (max-width: 1130px) {
      padding-left: 0;

      .card {
        padding: 35px 25px;

        &-title {
          margin-bottom: 15px;
        }
      }
    }

    @media (min-width: 2000px) {
      .card-inner {
        max-width: 1000px !important;
      }
    }

    @media (max-width: 1200px) {
      .wrapper {
        .img {
          background-size: auto 101%;
        }
      }
    }

    @media (max-width: 767px) {
      .img {
        display: none;
      }

      .card-inner {
        max-width: 100% !important;
      }
    }
  }

  &-bottom {
    width: 100%;
    margin: 200px 0 100px;
    text-align: center;
    padding: 0 20px;

    p {
      width: 100%;
      margin: auto;
      max-width: 1022px;
      font-size: 32px;
      font-weight: bold;
      color: #54827d;
    }

    .btn {
      max-width: fit-content;
      padding: 20px 30px;
      margin: 50px auto;
      background-color: #54827d !important;
    }

    .logo {
      height: 70px;
    }
  }
}