footer {
  background-color: $greenDark;
  color: $white;
  padding: 100px 25px;
  .footer-right {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 0 32px;
    height: 100%;
    .content {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .member {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        margin-top: 8px;
        padding: 0 10px;
      }
    }
  }
  .footer-top {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 32px 20px;
    display: flex;
    justify-content: space-between;

    .styled-text {
      font-size: 36px;
      line-height: 38px;
      font-weight: 900;
    }
  }
  .btns-wrapper {
    flex-wrap: wrap;
    margin-left: auto;
    display: flex;
    .btn {
      width: 100%;
      max-width: 100%;
    }
  }
  .footer-bottom {
    margin-top: 40px;
    .nav-list {
      ul {
        display: flex;
      }
      li {
        margin-right: 25px;
        text-align: center;
      }
      a {
        color: $white;
        font-size: 15px;
        font-weight: 500;
        margin-right: 8px;
        text-decoration: none;
      }
    }
    .nav-social {
      ul {
        display: flex;
        margin-left: auto;
        justify-content: flex-end;
      }
      li {
        margin-left: 40px;
      }
      svg {
        color: $white;
      }
    }
    .cr {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      opacity: 0.5;
    }
    .footer-privacy {
      color: $white;
      border-top: 1px solid $white;
      padding-top: 22px;
      margin-top: 22px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 15px;
          margin-bottom: 10px;
        }
        a {
          color: $white;
          text-decoration: none;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .footer-logo-dk {
        text-align: center;
        img {
          width: 120px;
          padding: 8px;
        }
      }
      .footer-logo-mb {
        display: none;
        img {
          width: 100%;
          max-width: 200px;
          padding: 10px;
        }
      }
    }
  }
  @media (max-width: 899px) {
    padding: 60px 25px;
    .btns-wrapper {
      margin-top: 16px;
      justify-content: space-between;
      .btn {
        max-width: 49%;
        margin: 0;
      }
    }
    .footer-right {
      .content {
        margin-top: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
      }
    }
    .footer-top {
      padding: 20px 10px;
    }
    .footer-bottom {
      .nav-list {
        justify-content: flex-start;
        ul {
          flex-direction: column;
        }
        li {
          text-align: left;
        }
        a {
          padding: 5px 0;
          display: block;
        }
      }
      .nav-social {
        ul {
          justify-content: flex-start;
        }
        li {
          margin-left: 0;
          margin-right: 40px;
        }
      }
      .footer-privacy {
        text-align: center;
        .footer-logo-dk {
          display: none;
        }
        .footer-logo-mb {
          justify-content: center;
          display: flex;
        }
        ul {
          margin: auto;
          justify-content: center;
          margin-top: 20px;
          li {
            margin-left: 0;
            margin-right: 15px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 662px) {
    .btns-wrapper {
      flex-direction: column;
      .btn {
        max-width: 100%;
        margin: 6px 0;
      }
    }
  }
}
