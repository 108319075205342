.styled-text {
  &-top {
    display: block;
    font-size: 20px;
    line-height: 44px;
    font-weight: 500;
    span {
      font-weight: 900;
    }
  }
  &-bottom {
    display: block;
    font-size: 50px;
    line-height: 48px;
    font-weight: 900;
    span {
      color: $gold;
    }
  }
  @media (max-width: 899px) {
  &-bottom {
    font-size: 38px;
    line-height: 38px;
  }
  }
}
