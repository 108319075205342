@import './reset.scss';
@import './fonts.scss';
b {
  font-weight: 900;
}
.text-privacy {
  ul, ol {
    list-style: disc;
    padding-left: 20px;
    li {
      padding: 6px 0;
    }
  }
  p {
    margin: 18px 0;
    font-size: 16px;
    line-height: 20px;
  }
  em {
    font-weight: bold;
  }
  strong {
    font-weight: 700;
  }
  table {
    tr {
      border: 1px solid $black;
      div {
        padding: 8px;
      }
    }
    td {
      border: 1px solid $black;
    }
  }
}
