$white: #fff;
$black: #1b1b1b;
$gray: #616161;
$gold: #cd961d;
$goldDark: #b9764f;
$goldLight: #eddcd0;
$goldLighten: #faf5f0;
$goldLighten2: #f1e4d5;
$goldGradient: linear-gradient(154.54deg, #e0b17b 0%, #a45f37 125.7%);
$goldGradient2: linear-gradient(154.52deg, #f1e4d5 0%, #b9764f 100%);
$goldLightGradient: linear-gradient(150deg, #f1e4d5  0%, #e0b9a9 100%);
$greenDark: #3e4a49;
$greenLight: #98aca8;
$greenLighten: #d9dad6;
$greenLighten2: #ebebe5;
$greenLighten3: rgba(177, 197, 191, 0.1);
$greenLighten4: rgba(177, 197, 191);
$greenLighten5: #ebebe5;
$greenGradient: linear-gradient(333.25deg, #4b6562 -0.01%, #cfdfda 158.72%);
$greenLightGradient: linear-gradient(
  333.25deg,
  #4b6562 0%,
  #cfdfda 100%
);
$greenGradient2: linear-gradient(151.6deg, #59706d 0%, #b1c5bf 213.27%);
