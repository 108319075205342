.error-block {
  .error-message {
    color: #3e4a49;
    h2 {
      font-size: 40px;
      font-weight: 700px;
      line-height: 22px;
      display: block;
    }
    p {
      font-size: 20px;
      line-height: 22px;
      display: block;
      margin: 40px 0;
    }
    .btn {
      margin: 80px 0 0;
    }
  }
  .error-image {
    display: flex;
    justify-content: center;
    img {
      max-width: 600px;
      width: 100%;
    }
  }
  @media (max-width: 899px) {
    .error-message {
      .btn {
        margin: 20px 0 0 ;
      }
    }
  }
}
