.steps-two-wrapper {
  margin-bottom: 110px;

  @media (max-width: 899px) {
    margin-bottom: 40px;
  }
}

.steps-two {
  color: $black;
  background-color: $greenLighten3;
  padding: 110px 75px !important;

  h3 {
    padding: 30px 30px 30px 0;
    font-size: 50px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;

    .highlight {
      color: $gold;
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;

    >div {
      >div {
        margin: 0;

        &:nth-child(1) {
          .card {
            height: auto;
            width: 100%;
          }

          .card-left {
            width: 60%;

            .card-body {
              margin-bottom: 18px;
            }
          }

          .card-content {
            width: 100%;
            max-width: 1150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-right: auto;
            margin-left: auto;
          }


          .card-right {
            width: 40%;
            display: flex;
            justify-content: flex-end;
          }

          @media (max-width: 899px) {
            .card-content {
              max-width: 468px;
              margin: auto;
              flex-direction: column;
            }

            .card-right,
            .card-left {
              width: 100%;
            }

            .card-right {
              justify-content: center;
            }
          }
        }
      }
    }

    .card {
      box-sizing: border-box;
      margin: 25px 0;
      padding: 70px 30px;
      background-color: $white;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;

      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);

      .card-content {
        margin: auto;
        height: 100%;
        max-width: 468px;
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &-title {
        font-size: 22px;
        line-height: 26px;
        font-weight: 900;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        .number {
          background-color: $goldDark;
          color: $white;
          width: 38px;
          height: 38px;
          min-width: 38px;
          min-height: 38px;
          font-size: 22px;
          font-weight: 900;
          line-height: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
        }
      }

      &-body {
        font-size: 18px;
        font-weight: 400;
        line-height: 33px;
      }

      .btns {
        display: flex;
        flex-direction: column;
      }

      .btn {
        margin: 0;

        &:nth-child(1) {
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }
  }

  @media (max-width: 1084px) {
    .cards {
      .card {
        padding: 40px 20px;
      }
    }
  }

  @media (max-width: 899px) {
    padding: 40px 20px !important;

    h3 {
      font-size: 36px;
      line-height: 38px;
      padding: 10px 0;
    }

    .cards {
      .btns {
        width: 100%;
        align-items: center;
      }

      .card {
        &-title {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}