.small-intro {
    .right-side-container {

        margin-top: 0px !important;

        @media (max-width: 899px) {
            margin-top: 50px !important;

        }
    }
}