.cards-wrapper {
  margin: 50px auto !important;
  padding: 0 18px;
  .cards {
    background-color: $goldLight;
    border-radius: 15px;
    color: $black;
    width: 100%;
    padding: 0 20px;
    .card {
      padding: 48px 20px;
      margin: 0;
      .title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 8px;
        text-align: center;
        padding: 0 5px;
      }
      .details {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 899px) {
  .cards-wrapper {
    padding: 20px;
    margin: 40px auto !important;
    background-color: transparent;
    .cards {
      border-radius: 20px;
      padding: 10px !important;
      .card {
        padding: 25px 25px;
      }
    }
  }
}
