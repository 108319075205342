.partners {
  padding: 130px 20px;
  .warpper {
    width: 100%;
    position: relative;
  }
  .man {
    position: absolute;
    top: -30px;
    left: 6%;
    @media (max-width: 1446px) {
      left: 20px;
    }
    @media (max-width: 1190px) {
      left: -30px;
    }

    @media (max-width: 827px) {
      width: 100px;
      left: 10px;
      top: -60%;
    }
  }
  .top {
    background: #cfd345;
    border-bottom: 15px solid #529546;
    padding: 50px 10px;
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      color: $white;
    @media (max-width: 1190px) {
      font-size: 28px;
    }
    }
  }
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 830px;
    margin: 20px auto 0;
    span {
      text-align: left;
      padding: 0 15px;
      font-size: 16px;
      &:after {
        content: '';
        display: block;
        width: calc(100% + 25px);
        position: relative;
        left: -5px;
        height: 8px;
        background-color: #d9d9d9;
        border-radius: 8px;
        margin-top: 4px;
      }
    }
    img {
      &:first-of-type {
        width: 200px;
        max-width: 30%;
      }
      &:last-of-type {
        width: 300px;
        max-width: 40%;
      }
    }
    @media (max-width: 1190px) {
      max-width: 70%;
    }
    @media (max-width: 827px) {
      max-width: 100%;
      span {
        font-size: 14px;
       &:after {
         height: 4px;
       } 
      }
    }
  }
}
