.steps-wrapper {
  padding: 80px 0;
  background-color: $goldLighten;
  background-image: url("https://i.imgur.com/hYXPRfL.png");
  background-repeat: no-repeat;
  background-size: 100% 337px;
  background-position: center 90%;
}
.steps {
  color: $black;
  h2 {
    padding: 30px 30px;
    font-size: 50px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    max-width: 60%;
    b {
      font-weight: 900;
    }
    .small {
      font-size: 20px;
      font-weight: 500;
      display: block;
    }
    .highlight {
      color: $gold;
    }
  }
  .cards {
    display: flex;
    height: 280px;
    justify-content: space-between;
    .card {
      width: 100%;
      max-width: 348px;
      height: 185px;
      margin-right: 15px;
      margin-left: 15px;
      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 30px 15px;
      }
      &:nth-child(1) {
        background-color: $goldLight;
        align-self: flex-end;
        .card-title {
          color: $goldDark;
        }
        .number {
          background-color: $goldDark;
        }
      }
      &:nth-child(2) {
        background-color: $goldLighten2;
        .card-title {
          color: $gold;
        }
        .number {
          background-color: $gold;
        }
      }
      &:nth-child(3) {
        background-color: $greenLighten;
        align-self: flex-end;
        .card-title {
          color: $greenLight;
        }
        .number {
          background-color: $greenLight;
        }
      }
      &:nth-child(4) {
        background-color: $greenLighten2;
        .card-title {
          color: $greenLighten4;
        }
        .number {
          background-color: $greenLighten4;
        }
      }
      .card-title {
        display: flex;
        text-transform: uppercase;
        margin-bottom: 25px;
        align-items: center;
        .number {
          font-size: 22px;
          font-weight: 900;
          color: $white;
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        .t-text {
          padding-top: 2px;
        }
        font-size: 18px;
        font-weight: 900;
        line-height: 19px;
      }
    }
  }
}
@media (max-width: 899px) {
  .steps-wrapper {
    background-image: none;
  }
  .steps {
    position: relative;
    overflow: hidden;
    height: auto;
    &:after {
      content: "";
      position: absolute;
      background-image: url("https://i.imgur.com/hYXPRfL.png");
      background-repeat: no-repeat;
      background-position: center;
      top: 0;
      left: -587px;
      width: 250%;
      height: 100%;
      z-index: 0;
      transform: rotate(215deg) scale(1.5);
    }
    h2 {
      position: relative;
      background-color: $goldLighten;
      z-index: 10;
      font-size: 36px;
      font-weight: 900;
      line-height: 40px;
      max-width: 100%;
      .small {
        font-size: 20px;
        font-weight: 500;
        line-height: 44px;
      }
    }
    .cards {
      z-index: 1;
      flex-direction: column;
      height: auto;
      .card {
        margin: 30px auto;
        text-align: left;
        .card-content {
          .card-title {
            align-self: flex-start;
          }
        }
        .number {
          position: relative;
          // top: -70px;
          // left: 10px;
        }
      }
    }
  }
}
