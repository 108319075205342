.small-intro {
    .right-side-container {
        margin-top: 0;
    }

    .red-term {
        &::before {
            background-color: #e9e9e9 !important;
        }
    }

    .right-side {
        background-color: #fffbf8;
        margin: auto;
        border-radius: 20px;
        padding: 94px 68px 113px 90px;

        @media (max-width: 1465px) {
            padding: 30px;
        }

        h2 {
            font-size: 32px;
            line-height: 42px;
            font-weight: 900;
            text-transform: uppercase;
            color: #1B1B1B;

            @media (max-width: 1465px) {
                font-size: 25px;
                line-height: 32px;
                text-align: center;

            }
        }

        .switcher-container {
            margin-top: 20px;
            margin-bottom: 26px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                text-transform: uppercase;
                font-size: 26px;
                line-height: 26px;
                font-weight: 900;
                user-select: none;
                transition: ease-in-out .5s;

                @media (max-width: 1465px) {
                    font-size: 20px;
                    line-height: 20px;

                }

                &:first-of-type {
                    color: $greenLight;
                }

                &:last-of-type {
                    color: $goldDark;

                }
            }

            .switcher {
                background-color: #f2f2f2;
                appearance: none;
                width: 47px;
                height: 19px;
                border-radius: 20px;
                border: 1px solid #D6D6D6;
                position: relative;

                &:checked {
                    &::before {
                        background-color: $greenLight;
                        left: 0;
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    right: 0;
                    width: 17px;
                    height: 17px;
                    background-color: $goldDark;
                    border-radius: 20px;
                    transition: all ease-in-out .3s;
                }
            }
        }

        .btn-submit-pro,
        .btn-submit-ren {
            text-transform: uppercase;
            width: 295px;
            height: 80px;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
            text-decoration: none;
            cursor: pointer;
            background-color: $goldDark;
            border: none;
            margin-top: 30px;

            &:hover {
                .icon {
                    transform: rotate(90deg);
                }
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                border: 2px solid $white;
                transition: transform ease-in-out .4s;
            }

            @media (max-width: 1465px) {
                width: 240px;
                height: 60px;
            }

        }

        .btn-submit-ren {
            background-color: $greenLight;
        }
    }
}