.form {
  &-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 26px;
    padding: 30px 0;
    border-bottom: 1px solid $gold;
    margin-bottom: 30px;
  }

  &-label {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.05em;
    margin: 6px 0;
  }

  &-controllers {
    >div {
      position: relative;
      margin-top: 0;
    }
  }

  &-control {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    width: 100%;
    height: 50px;
    margin: 6px 0;
    padding: 10px;
    background-color: $white;
    border: 1px solid $gold;

    &[type="checkbox"],
    &[type="radio"] {
      position: absolute;
      top: 14px;
      left: 0;
    }

    >div {
      border: none;
    }

    &-select {
      >div {
        padding-top: 5px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }

    &::placeholder {
      color: $greenLighten;
    }

    &-wrapper {

      &-checkbox,
      &-radio {
        position: relative;
        padding-left: 20px !important;
        margin-top: 6px !important;
        margin-bottom: 6px !important;
      }
    }

    &-info {
      display: inline-block;
      color: $white;
      margin-left: 10px;
      z-index: 2;

      &:hover {
        .info-content {
          display: block;
        }
      }

      .icon {
        background-color: $black;
        color: $white;
        font-family: sans-serif;
        width: 18px;
        height: 18px;
        text-align: center;
        border-radius: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info-content {
        position: absolute;
        z-index: 1;
        background-color: $black;
        border-radius: 3px;
        width: 150px;
        padding: 10px;
        display: none;
        font-size: 12px;
      }
    }
  }

  .form-captch {
    margin-top: 10px;
    display: block;
    width: 100%;

    @media only screen and (max-width: 340px) {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }

  .error {
    color: red;
    display: block;
    font-size: 15px;
    margin-bottom: 8px;

    &-captch {
      margin-top: 10px;
    }
  }

  label {
    position: relative;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;

    &.tandc {
      font-size: 14px;
      line-height: 16px;
    }
  }

  input {
    position: relative;

    &[type="checkbox"],
    &[type="radio"] {
      &:checked {
        &:after {
          opacity: 1;
        }
      }

      background-color: blue;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin-left: 20px;
      margin-bottom: 10px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background: $white;
        z-index: 0;
        left: -20px;
        top: -14px;
      }

      &:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: green;
        z-index: 0;
        left: -16px;
        top: -10px;
      }
    }
  }

  .btn {
    margin: 30px 0 30px auto;
  }

  @media (max-width: 899px) {
    &-title {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      padding: 30px 0 10px;
      margin-bottom: 10px;
    }

    label {
      font-size: 18px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.05em;
    }

    &-control {
      margin-bottom: 15px;
    }

    .btn {
      margin: 20px 0 20px auto;
    }
  }
}