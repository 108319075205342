.intro {
  background-color: $black;
  height: 620px;
  background-position: 20% center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  position: relative;
  color: $white;
  h1 {
    font-size: 60px;
    font-weight: 900;
    line-height: 62px;
    position: absolute;
    top: 30%;
    .animated-text {
      color: $gold;
      display: inline;
    }
  }
  @media (max-width: 767px) {
    height: 400px;
    padding: 25px;
    overflow: hidden;
    h1 {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
      .small {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
  }
}

@keyframes moveText {
  0% {
    bottom: 0;
  }
  20% {
    bottom: 0;
  }
  30% {
    bottom: 90%;
  }
  70% {
    bottom: 90%;
  }
  80% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}
