.small-intro {
    .left-side {
        padding-left: 28px;
        padding-right: 28px;

        @media (max-width: 1465px) {
            padding-left: 18px;
        }

        h2 {
            text-transform: uppercase;
            font-size: 50px;
            line-height: 48px;
            font-weight: 900;
            margin-bottom: 20px;

            @media (max-width: 1465px) {
                font-size: 34px;
            }
        }

        .renouvellement {
            margin-bottom: 73px;

            @media (max-width: 1465px) {
                margin-bottom: 40px;
            }

            h2 {
                color: $greenLight;
                // color: #839995;
            }

            .pill {
                background-color: $greenLight;
            }

            .left-side-btn {
                background-color: $greenLight;
            }
        }

        .prolongation {
            h2 {
                color: $goldDark;
            }

            ul {
                li {
                    .pill {
                        background-color: $goldDark;
                    }
                }
            }

            .left-side-btn {
                background-color: $goldDark;
            }
        }

        ul {
            li {
                position: relative;
                margin-left: 28px;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 20px;

                @media (max-width: 1465px) {
                    font-size: 14px;
                }
            }

            .pill {
                width: 17px;
                height: 17px;
                position: absolute;
                border-radius: 100%;
                left: -28px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .left-side-btn {
            text-transform: uppercase;
            width: 295px;
            height: 60px;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
            text-decoration: none;
            cursor: pointer;

            @media (max-width: 1465px) {
                font-size: 14px;
                width: 195px;
                height: 55px;
            }

            &:hover {
                .icon {
                    transform: rotate(90deg);
                }
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                border: 2px solid $white;
                transition: transform ease-in-out .4s;
            }

        }

        .bold {
            font-weight: 700;
        }

    }
}