.text-sides-wrapper {
  color: $black;
  padding: 22px 30px 50px;
  text-align: center;
  .text-sides {
    .left-side {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: text;
      h2 {
        text-transform: uppercase;
        width: 70%;
        margin: auto auto 20px;
        font-size: 50px;
        line-height: 48px;
        font-weight: 900;
        span {
          color: $gold;
        }
      }
      @media screen and (max-width: 1200px) {
        h2 {
          font-size: 36px;
          line-height: 40px;
          width: 100%;
        }
      }
    }
  }
  .right-side {
    color: $black;
    .p-container {
      width: 78%;
      margin: auto;
    }
    p {
      font-size: 16px;
      line-height: 33px;
      font-weight: 400;
      a,
      b {
        font-weight: 700;
        text-decoration: none;
      }
    }
    .promo-wrapper {
      width: 100%;
      max-width: 542px;
      margin: auto;
      position: relative;
      .promo {
        background-color: #cd961d;
        margin: 10px;
        margin: 40px auto 0;
        &:hover {
          .icon-wrapper {
            transform: rotate(90deg);
          }
        }
        &-inner {
          color: $white;
          font-size: 16px;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          .icon-wrapper {
            transition: transform .3s ease-in-out;
            width: 20px;
            height: 20px;
            padding: 8px;
            border: 2px solid $white;
            border-radius: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            position: absolute;
            right: 20px;
            @media (max-width: 462px) {
              position: relative;
              right: 4px ;
            }
          }
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
          }
          span {
            font-size: 48px;
            font-weight: 700;
            line-height: 52px;
            padding: 10px;
          }
        }
      }
      .info {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
}
@media (max-width: 899px) {
  .text-sides-wrapper {
    padding: 0 20px;
    margin-bottom: 40px;
    .left-side {
      margin-bottom: 30px;
    }
    .right-side {
      .promo {
        padding: 0px !important;
        .icon-wrapper {
          width: 10px !important;
          height: 10px !important;
        }
        p {
          padding: 0 8px !important;
          font-size: 12px;
          line-height: 14px;
          span {
            padding: 10px;
            font-size: 22px !important;
            line-height: 20px;
          }
        }
      }
    }
  }
}
