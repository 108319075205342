.card-sides-wrapper {
  color: $white;
  width: 100%;
  max-width: 1800px;
  margin: auto;

  .card-sides {
    position: relative;
    align-items: stretch;

    .car {
      position: absolute;
      width: 40%;
      left: 30%;
      bottom: 10%;
    }

    .car-mb {
      display: none;
    }
  }

  .block-title {
    font-weight: 900;
    font-size: 50px;
    line-height: 48px;
    text-align: center;
    color: #1b1b1b;
    margin-bottom: 30px;
  }

  .right-side,
  .left-side {
    transition: transform 0.4s ease-in-out;

    &:hover {
      transform: scale(1.05);
      z-index: 100;
    }

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 0;

    .content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .content-inner {
        position: relative;
        padding-top: 10%;
      }

      .content {
        //padding: 100px 20px 100px;
        padding: 100px 20px 250px;
        position: relative;
        z-index: 3;
        width: 80%;
      }

      .text-content {
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          align-items: flex-start;
        }

        b {
          background-color: $white;
          min-width: 32px;
          height: 32px;
          color: $black;
          margin-right: 10px;
          white-space: nowrap;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }

      .top {
        font-size: 18px;
        font-weight: 400;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 0;

        b {
          font-weight: 900;
        }
      }

      .title {
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 16px 0;
      }

      .subtitle {
        padding-right: 20px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 28px;
      }
    }

    position: relative;

    .bottom {
      position: absolute;
      top: calc(62% + 94px);
      left: 40px;
      z-index: 10;
      width: 100%;

      @media (min-width: 1235px) {
        top: calc(62% + 60px);
      }

      .indecator {
        margin-top: 40px;
        font-size: 12px;
        line-height: 14px;
        display: block;
        width: 70%;

        &-mb {
          display: none;
        }
      }
    }

    .text-content {
      p {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        margin: 15px 0;

        b {
          font-weight: 900;
        }

        &.indecator-mb {
          margin-top: 40px;
          font-size: 12px;
          line-height: 14px;
          display: block;
          display: none;
        }
      }
    }

    .btn {
      margin-top: 30px;
      margin-left: 0;

      &-mb {
        display: none;
      }
    }
  }

  .left-side {
    overflow-wrap: break-word;
    align-items: flex-start;
    text-align: left;

    .content-wrapper {
      position: relative;
      padding-right: 20px;
      padding-left: 20px;

      // padding-top: 10%;
      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 120%;
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        background: linear-gradient(329.67deg, #4b6562 -0.47%, #cfdfda 158.99%);
        background-color: red;
      }
    }

    .bottom {
      left: 38px;
    }

    .content {
      .text-content {
        b {
          color: $greenDark;
        }
      }

      p {
        display: block;
      }
    }
  }

  .right-side {
    border-radius: 0 20px 20px 0;

    .content-wrapper {
      position: relative;
      height: calc(80% + 56px);

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        right: 0;
        top: 10%;
        width: 120%;
        height: 100%;
        clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
        background: linear-gradient(143.52deg,
            #e5c6a8 -21.54%,
            #c28760 98.31%,
            #c28760 98.31%);
        background-color: red;
      }
    }

    .promo {
      display: none;
      position: absolute;
      border-radius: 15px;
      top: 45px;
      right: 0;
      z-index: 4;
      padding: 0 18px;
      width: 50%;
      min-width: 300px;

      .bg {
        &:after {
          content: '';
          position: absolute;
          background-color: $greenLight;
          top: 0;
          right: 0;
          z-index: 0;
          width: 100%;
          min-width: 350px;
          height: 73px;
          clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
        }
      }

      .promo-content {
        position: relative;
        z-index: 8;

        p {
          margin: 0;
          text-align: center;
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 64px;
            line-height: 53px;
            font-weight: 700;
            position: relative;
            margin: 10px 30px;
            left: -10px;

            &:after {
              content: '%';
              position: absolute;
              top: -10px;
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .content-inner {
      padding: 0 20px;
    }

    .content {
      .text-content {
        margin-bottom: 60px;

        b {
          min-width: 106px;
          color: #c58c65;
        }
      }
    }
  }
}

@media (max-width: 899px) {
  .card-sides-wrapper {
    padding: 0;
    height: auto;
    padding-right: 0;

    .car {
      display: none;
    }

    .block-title {
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: #1b1b1b;
      margin-bottom: 10px;
    }

    .right-side,
    .left-side {
      position: relative;
      height: auto;
      align-items: center;

      .content-wrapper {
        height: auto;
        padding: 0;

        &:before {
          display: none;
        }

        .content {
          padding: 20px;
          width: auto !important;
          max-width: 100%;
        }
      }

      .bottom {
        display: none;
      }

      .btn {
        display: flex;
      }
    }

    .right-side {
      border-radius: 0;
      position: relative;

      .content-wrapper {
        background: linear-gradient(143.52deg,
            #e5c6a8 -21.54%,
            #c28760 98.31%,
            #c28760 98.31%);

        .content-inner {
          margin-top: 30px;
          padding-right: 0;
          padding-left: 0;

          &:after {
            position: relative;
            display: none;
            top: 0;
            width: 100%;
          }
        }
      }

      .promo {
        margin-left: auto;
        width: 100%;
        max-width: 500px;
        position: absolute;
        top: -54px;
        height: auto;

        .promo-content {
          p {
            flex-wrap: wrap;

            span {
              font-size: 40px;
              margin: 10px 20px;

              &:after {
                font-size: 20px;
              }
            }
          }
        }

        background-color: $greenLight;
      }

      .content {
        .text-content {
          margin-bottom: 0;

          .indecator-mb {
            display: block;
          }
        }
      }
    }

    .left-side {
      .content-wrapper {
        margin-top: auto;
        background: linear-gradient(329.67deg, #4b6562 -0.47%, #cfdfda 158.99%);
        padding-bottom: 40px;

        .content-inner {
          margin-top: 0;
        }
      }
    }
  }
}