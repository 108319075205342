.items {
  > div {
    margin: 0;
  }
  .item {
    padding: 0 15px;
  }
  .item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon {
      width: 150px;
      height: 150px;
      border-radius: 80px;
      background: #faf5f0;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 80px;
      }
    }
    .item-text {
      font-size: 18px;
      text-align: center;
      color: #1b1b1b;
      b {
        display: block;
        line-height: 33px;
        margin: 32px 0 20px;
        text-transform: uppercase;
      }
      p {
        line-height: 28px;
      }
    }
  }
  @media (max-width: 899px) {
    .item-content {
      .icon {
        width: 120px;
        height: 120px;
        img {
          width: 60px;
        }
      }
      .item-text {
        font-size: 16px;
        b {
          margin: 28px 0 10px;
        }
      }
    }
  }
}
