.block-renew,
.block-prolong {
  padding: 100px 0;
  background-color: $goldLighten;
  border-radius: 15px;
  color: $black;
  width: 100%;

  h3 {
    font-size: 48px;
    font-weight: 900;
    line-height: 50px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 12px !important;

    .top {
      font-size: 20px;
      font-weight: 500;
      line-height: 44px;
      display: block;
    }

    .highlight {
      color: $goldDark;
    }

    .small {
      display: block;
      font-size: 28px;
      font-weight: 400;
      line-height: 45px;
    }
  }

  .subtitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;

    span {
      display: inline-block;
    }
  }

  @media (max-width: 899px) {
    h3 {
      font-size: 36px;
      font-weight: 900;
      line-height: 40px;
      margin-bottom: 0;

      .top {
        font-size: 18px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 0em;
      }
    }

    .subtitle {
      font-size: 22px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
    }
  }
}